export default {
    created(el){
        el.style.boxSizing = 'border-box';
        el.style.resize = 'none';
        var offset = el.offsetHeight - el.clientHeight,
            padding = 0;
        el.addEventListener('input', function(){
            el.style.height = 'auto';
            el.style.height = el.scrollHeight + offset + padding + 'px';
        });

        setTimeout(function(){
            var style = (el.currentStyle || window.getComputedStyle(el));
            padding = parseInt(style.paddingTop.replaceAll(/([^0-9]+)/g, '')), parseInt(style.paddingBottom.replaceAll(/([^0-9]+)/g, ''));
            el.style.height = el.scrollHeight + offset + padding + 'px';
        }, 500);
    }
}