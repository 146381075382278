/*
 * vue-izitoast | v1.0.0
 * http://arthurvasconcelos.com.br
 * by Arthur Vasconcelos.
 */

import './toast.css'
import iziToast from './toast'

export default {
    install: (app, options) => {
        const defaultOptions = {
            zindex: 99999,
            layout: 1,
            balloon: false,
            close: true,
            closeOnEscape: false,
            rtl: false,
            position: 'bottomRight',
            timeout: 5000,
            animateInside: true,
            drag: false,
            pauseOnHover: true,
            resetOnHover: false,
            transitionIn: 'fadeInUp',
            transitionOut: 'fadeOut',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            buttons: {},
            inputs: {},
            onOpening: function () {},
            onOpened: function () {},
            onClosing: function () {},
            onClosed: function () {}
        };

        iziToast.settings(Object.assign({}, defaultOptions, options));

        app.config.globalProperties.$toast = {
            error(message, title = '', options = {}){
                iziToast.error(Object.assign({}, options, { message, title }));
            },
            info(message, title = '', options = {}) {
                iziToast.info(Object.assign({}, options, { message, title }));
            },
            success(message, title = '', options = {}) {
                iziToast.success(Object.assign({}, options, { message, title }));
            },
            warning(message, title = '', options = {}) {
                iziToast.warning(Object.assign({}, options, { message, title }));
            }
        };
    }
}