require('./bootstrap');

import { createApp, defineAsyncComponent } from 'vue'
import iziToast from '@js/plugins/toast'
import Autosize from '@js/directives/autosize'
const Feedback = defineAsyncComponent(() => import('@js/components/Feedback.vue'))

createApp({})
    .use(iziToast)
    .component('feedback-form', Feedback)
    .directive('autosize', Autosize)
    .mount('#app');

import { Fancybox } from "@js/vendor/fancybox-ui/src/Fancybox/Fancybox.js";
import ru from "@js/vendor/fancybox-ui/src/Fancybox/l10n/ru.js";
Fancybox.bind('.colorbox', {
    groupAttr: 'rel',
    infinite: false,
    l10n: ru
});